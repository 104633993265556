import React from "react";
import './Services.css'

function Services(){
    return(
        <div>
            <section className="cDarkGreyligBG cProductsSolutions" id="services">
                <div className="container mb-5">

                    <h1 className="h1 font-weight-bold mb-5 text-center text-white extra-h1">SERVICES</h1>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-3 my-4">
                            <div className="card cProductCard border">
                                <div className="cCardIcon text-center py-4">
                                    <img className="img-fluid" src="../img/s1.png" alt="" width="30%" />
                                </div>
                                <h4 className="text-white text-center mb-2">Solution Provider</h4>
                                <div className="card-body text-center">
                                    <p className="card-title text-white crop-text-5">Provide enterprise-grade,
                                        innovative and distinct digital fintech solutions anchored in BFSI, Retail,
                                        Transport, Government etc. to our client’s, which are turnkey, unique, and
                                        continuously updated with the industry standards and tech disruptions.</p>

                                    <a href="/" id="iHOME-APIM-CTA" className="cButton">EXPLORE </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 my-4">
                            <div className="card cProductCard border">
                                <div className="cCardIcon text-center py-4">
                                    <img className="img-fluid" src="../img/s2.png" alt="" width="30%" />
                                </div>
                                <h4 className="text-white text-center mb-2">Solution Integrator</h4>
                                <div className="card-body text-center">
                                    <h5 className="card-title text-white crop-text-5">Lead the process of combining
                                        the state-of-art technologies which are scalable, efficient, and secure with
                                        implementation and project management best practices and strategies to help
                                        business technical transformational and integrational goals of the
                                        clients.</h5>

                                    <a href="/" id="iHOME-EI-SI" className="cButton">EXPLORE </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 my-4">
                            <div className="card cProductCard border">
                                <div className="cCardIcon text-center py-4">
                                    <img className="img-fluid" src="../img/s3.png" alt="" width="30%" />
                                </div>
                                <h4 className="text-white text-center mb-2">Research & Development</h4>
                                <div className="card-body text-center">
                                    <p className="card-title text-white crop-text-5">We believe learning has no end.
                                        With powerful insights from our R&D team, we continuously improving our
                                        technical and business stack to provide innovative, up-to-the-date, most
                                        secured, patent product services to our clients which allow them to survive
                                        and thrive in this dynamic digital world.</p>

                                    <a href="/" id="iHOME-IS-CTA" className="cButton">EXPLORE </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 my-4">
                            <div className="card cProductCard border">
                                <div className="cCardIcon text-center py-4">
                                    <img className="img-fluid" src="../img/s4.png" alt="" width="30%" />
                                </div>
                                <h4 className="text-white text-center mb-2">Local Partner</h4>
                                <div className="card-body text-center">
                                    <p className="card-title text-white crop-text-5">Specialized services to
                                        world-renowned software and hardware vendors and as their local partner
                                        partner with a long-standing & trusted business technical relationship.</p>

                                    <a href="/" id="iHOME-OB-CTA" className="cButton">EXPLORE </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 my-4">
                            <div className="card cProductCard border">
                                <div className="cCardIcon text-center py-4">
                                    <img className="img-fluid" src="../img/s5.png" alt="" width="30%" />
                                </div>
                                <h4 className="text-white text-center mb-2">Solution Consultancy</h4>
                                <div className="card-body text-center">
                                    <p className="card-title text-white crop-text-5">By understanding unique
                                        business needs, challenges and help them to find solutions to enhance their
                                        business through a range of technical, operational, strategical
                                        assistance.</p>

                                    <a href="/" id="iHOME-OH-CTA" className="cButton">EXPLORE </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 my-4">
                            <div className="card cProductCard border">
                                <div className="cCardIcon text-center py-4">
                                    <img className="img-fluid" src="../img/s6.png" alt="" width="30%" />
                                </div>
                                <h4 className="text-white text-center mb-2">Engineering Services</h4>
                                <div className="card-body text-center">
                                    <p className="card-title text-white crop-text-5">Highly experienced engineering
                                        team who can build digital & fintech business echo systems with the industry
                                        grade standards, fusing creativity, talent, and Innovations, combining
                                        disruptive technologies.</p>

                                    <a href="/" id="iHOME-SC-CTA" className="cButton">EXPLORE </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 my-4">
                            <div className="card cProductCard border">
                                <div className="cCardIcon text-center py-4">
                                    <img className="img-fluid" src="../img/s7.png" alt="" width="30%" />
                                </div>
                                <h4 className="text-white text-center mb-2">Managed Services</h4>
                                <div className="card-body text-center">
                                    <p className="card-title text-white crop-text-5">Manage all IT platform
                                        operations remotely from our end (In-House) proactively, which enables
                                        continuous support, monitoring, troubleshooting, remediating issues which
                                        strategically allows improving operations and minimizing expenses burden for
                                        clients.</p>

                                    <a href="/" id="iHOME-SC-MS" className="cButton">EXPLORE </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 my-4">
                            <div className="card cProductCard border">
                                <div className="cCardIcon text-center py-4">
                                    <img className="img-fluid" src="../img/s8.png" alt="" width="30%" />
                                </div>
                                <h4 className="text-white text-center mb-2">Digital Transformation</h4>
                                <div className="card-body text-center">
                                    <p className="card-title text-white crop-text-5">Help our clients to build
                                        digital capabilities that can be deployed to re-envision customer
                                        experience, optimize business processes and create new business models that
                                        enables them to create a sustainable competitive advantage in the dynamic
                                        digital economy.</p>

                                    <a href="/" id="iHOME-SC-DT" className="cButton">EXPLORE </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;
