import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/css/style.css';
import './assets/css/owl.theme.default.css';

ReactDOM.render( <App />,document.getElementById('root'));


