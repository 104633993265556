import React from 'react'
import '../../App.css';
import Achievement from "../Achievement";
import Ourcustomers from "../Ourcustomers";
import Worldmap from "../Worldmap";

export default function Customers(){
    return(
        <div>
            <section className=" cHomepageHeader cDesktopHeader">
                <div className="container-fluid px-0">
                    <div className="bg-imagesmb">
                        <div className="container">
                            <div className="slide__content--headings px-2 px-lg-5 py-4 mx-lg-5 text-center">
                                <h2 className="animated text-center text-lg-center text-white mdf-h2" data-animation-in="fadeInUp">
                                    Customers Directory</h2>
                                <p className="animated text-center text-white px-lg-5" data-animation-in="fadeInUp"
                                   data-delay-in="0.3">
                                    Customers deliver validated services, solutions, and technologies to customers worldwide. Feel free to explore our partner directory or contact us for a referral.
                                </p>
                                <a href="/" id="iHOME-EI-CTA" className="cButton text-center">Explore More </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-white pb-2 my-0 my-lg-5 py-5">
                <div className="">
                    <div className="container">
                        <div className="toolbar mb2 mt2 " id="accordion">

                            <div className="">
                                <div className="">
                                        <div className="bbb_slider_container">
                                            <div className="row">
                                                <div className="bbb_item is_new col-md-3">
                                                    <div className=" d-flex flex-column align-items-center justify-content-center cliens">
                                                        <img className="img-fluid" src="../img/c1.png" alt=""/>
                                                    </div>
                                                </div>

                                                <div className="bbb_item col-md-3">
                                                    <div className=" d-flex flex-column align-items-center justify-content-center cliens">
                                                        <img className="img-fluid" src="../img/c2.png" alt=""/>
                                                    </div>
                                                </div>

                                                <div className="bbb_item col-md-3">
                                                    <div className=" d-flex flex-column align-items-center justify-content-center cliens">
                                                        <img className="img-fluid" src="../img/c3.png" alt=""/>
                                                    </div>
                                                </div>

                                                <div className="bbb_item is_new col-md-3">
                                                    <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                        <img className="img-fluid" src="../img/c4.png" alt=""/>
                                                    </div>
                                                </div>

                                                <div className="bbb_item col-md-3">
                                                    <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                        <img className="img-fluid" src="../img/c5.png" alt=""/>
                                                    </div>
                                                </div>

                                                <div className="bbb_item is_new col-md-3">
                                                    <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                        <img className="img-fluid" src="../img/c6.png" alt=""/>
                                                    </div>
                                                </div>

                                                <div className="bbb_item is_new col-md-3">
                                                    <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                        <img className="img-fluid" src="../img/c7.png" alt=""/>
                                                    </div>
                                                </div>

                                                <div className="bbb_item is_new col-md-3">
                                                    <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                        <img className="img-fluid" src="../img/c8.png" alt=""/>
                                                    </div>
                                                </div>

                                                <div className="bbb_item is_new col-md-3">
                                                    <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                        <img className="img-fluid" src="../img/c9.png" alt=""/>
                                                    </div>
                                                </div>

                                                <div className="bbb_item is_new col-md-3">
                                                    <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                        <img className="img-fluid" src="../img/c10.png" alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Achievement/>
            <Ourcustomers/>
            <Worldmap/>
        </div>
    );
}


