import React from "react";
import './Portfolio.css'

function Portfolio(){
    return(
        <section className="bg-white py-5 my-5">
            <div className="">
                <div className="container">

                    <div className="toolbar mb2 mt2">
                        <button className="btn btn-gallary fil-cat btn-lg" href="" data-rel="all">All</button>
                        <button className="btn btn-gallary fil-cat btn-lg" data-rel="project">Project</button>
                        <button className="btn btn-gallary fil-cat btn-lg" data-rel="product">Product</button>
                        <button className="btn btn-gallary fil-cat btn-lg" data-rel="pr">PR</button>
                        <button className="btn btn-gallary fil-cat btn-lg" data-rel="news">News</button>
                    </div>

                    <div id="portfolio">
                        <div className="tile scale-anm project all product">
                            <img className="img-fluid" src="../img/portfolio-1.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm all news">
                            <a href="https://www.facebook.com/1515213222065350/posts/2369139566672707/?d=n"
                               target="_blank" rel="noopener noreferrer">
                                <img className="img-fluid" src="../img/news01.jpeg" alt="" />
                            </a>
                        </div>
                        <div className="tile scale-anm bcards pr all">
                            <img className="img-fluid" src="../img/portfolio-2.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm project product all">
                            <img className="img-fluid" src="../img/portfolio-3.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm product project all">
                            <img className="img-fluid" src="../img/portfolio-6.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm all news">
                            <a href="https://www.facebook.com/1515213222065350/posts/silverleap-and-fellow-sri-lankan-it-solution-companies-showcases-digital-potenti/2374962946090369/"
                               target="_blank" rel="noopener noreferrer">
                                <img className="img-fluid" src="../img/news-02.jpg" alt="" />
                            </a>
                        </div>
                        <div className="tile scale-anm all product">
                            <img className="img-fluid" src="../img/portfolio-7.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm all pr">
                            <img className="img-fluid" src="../img/portfolio-8.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm all product">
                            <img className="img-fluid" src="../img/portfolio-10.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm all project">
                            <img className="img-fluid" src="../img/portfolio-11.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm project all">
                            <img className="img-fluid" src="../img/portfolio-12.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm product all">
                            <img className="img-fluid" src="../img/portfolio-13.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm all news">
                            <a href="https://www.ft.lk/IT-Telecom-Tech/Silverleap-with-LB-Finance-launches-LB-CIM-to-drive-digitisation-of-SL-payment-ecosystem/50-692840"
                               target="_blank" rel="noopener noreferrer">
                                <img className="img-fluid" src="../img/news-03.jpg" alt="" />
                            </a>
                        </div>
                        <div className="tile scale-anm all project">
                            <img className="img-fluid" src="../img/portfolio-14.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm pr all">
                            <img className="img-fluid" src="../img/portfolio-15.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm pr all">
                            <img className="img-fluid" src="../img/portfolio-16.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm pr all">
                            <img className="img-fluid" src="../img/portfolio-17.jpg" alt="" />
                        </div>
                        <div className="tile scale-anm pr all">
                            <img className="img-fluid" src="../img/portfolio-18.jpg" alt="" />
                        </div>

                    </div>

                </div>
            </div>
        </section>
    )
}

export default Portfolio;
