import React from 'react'
import './Worldmap.css';


function Worldmap() {
    return(
        <div>
            <section className="py-1">
                <div id="map">
                    <div className="img-container">
                        <img className="img-map" src="../img/map.webp" alt="Map" />
                    </div>
                    <div id="dots">
                        <div className="dot dot-15" />
                        <div className="dot dot-18" />
                        <div className="dot dot-21" />
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Worldmap;
