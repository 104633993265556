import React from "react";
import './Smartproduct.css';

function Smartproduct(){
    return (
        <div>
            <section className="cDarkGreyBG cAnalystReports bottonbg">
                <div className="container">
                    <div className="row cHighlightedRow">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="cHighlighted cCitations">
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                    <a id="iHOME-Forrester-APIM-CTA" href="/">
                                        <div className="cFrorresterAPIM pb-0">
                                            <div className="cReport">
                                                <h3> M&T FLAGSHIP PRODUCT </h3>
                                                <h2> Smart Transit </h2>
                                                <h4 className="crop-text-4 text-left">Comprehensive, Interoperable,
                                                    Open Standard and Cloud Ready Automated Fare Collection (AFC)
                                                    Platform to Track, Collect & Automate transportation operation
                                                    workflows, fleet, assets, routes, customers, drivers and other
                                                    stakeholders.</h4>

                                            </div>
                                            <div className="row">
                                                <div className="col-7 pt-3 px-0">
                                                    <div className="cCTA"><a href="/"> Explore More </a></div>
                                                </div>
                                                <div className="col-5 px-0">
                                                    <img className="img-fluid"
                                                         src="../img/5e.webp" alt=""
                                                         data-lazy="../img/5e.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                    <a id="iHOME-Forrester-IS-CTA" href="/">
                                        <div className="cKuppingercoleCIAM pb-0">
                                            <div className="cReport">
                                                <h3> BFSI FLAGSHIP PRODUCT </h3>
                                                <h2> Smart Pay </h2>
                                                <h4 className="crop-text-4 text-left">BFSI service providers such as
                                                    commercial banks, specialized banks, micro finance companies,
                                                    credit unions and other payment service providers to bestow
                                                    cashless, queue-less, hassle-less, financial inclusive neo
                                                    banking level services to consumers, merchants and SMEs.</h4>

                                            </div>
                                            <div className="row">
                                                <div className="col-7 pt-3 px-0">
                                                    <div className="cCTA"><a href="/">Explore More </a></div>
                                                </div>
                                                <div className="col-5 px-0">
                                                    <img className="img-fluid"
                                                         src="../img/2b.webp" alt=""
                                                         data-lazy="./img/2b.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-4">
                                    <a id="iHOME-Forrester-IS2-CTA" href="/">
                                        <div className="cFrorresterCIAM pb-0">
                                            <div className="cReport">
                                                <h3> RETAIL FLAGSHIP PRODUCT </h3>
                                                <h2> Smart Shopper </h2>
                                                <h4 className="crop-text-4 text-left"> Mobile centric, Digital
                                                    Marketplace & Payment Platform for all the users across entire
                                                    retail value chain, which creates a positive customer experience
                                                    and better synergy with whole stakeholders. </h4>

                                            </div>
                                            <div className="row">
                                                <div className="col-7 pt-3 px-0">
                                                    <div className="cCTA"><a href="/">Explore More </a></div>
                                                </div>
                                                <div className="col-5 px-0">
                                                    <img className="img-fluid"
                                                         src="../img/4d.webp" alt=""
                                                         data-lazy="../img/4d.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Smartproduct;


