import React, { useState } from 'react';
import 'semantic-ui-css/semantic.min.css'
import './Navbar.css';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react'



const Navheader = () => {

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    // const closeMobileMenu = () => setClick(false);

    return (
        <div className="cWSO2MobileNav">
            <nav className="navbar navbar-expand-lg navbar-mainbg">
                <NavLink className="navbar-brand navbar-logo logo-port" to="/" exact>
                    <img src="../img/embrace-4a.png" alt="Logo" />
                </NavLink>
                <div className="navbar-toggler menu-icon"
                    data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation" onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <div className="row bg-white pt-4">
                            <div className="col-6">
                                <NavLink className="navbar-brand navbar-logo logo-port" to="/" exact>
                                    <img src="../img/embrace-4a.png" alt="Logo" />
                                </NavLink>
                            </div>
                            <div className="col-6 text-right pt-3">
                                <div className="navbar-toggler menu-icon"
                                     data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                     aria-expanded="false" aria-label="Toggle navigation" onClick={handleClick}>
                                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                                </div>
                            </div>
                        </div>
                        <li className="nav-item ">
                            <span className="nav-link menu_outer pt-5">
                                <Dropdown text='Company' pointing className='link item nav-link menu_outer col-12 px-4'>
                                    <Dropdown.Menu className='col-12'>
                                        <Dropdown.Item className="my-3">About</Dropdown.Item>
                                        <Dropdown.Item className="my-3">Team</Dropdown.Item>
                                        <Dropdown.Item className="my-3">Careers</Dropdown.Item>
                                        <Dropdown.Item className="my-3">Community</Dropdown.Item>
                                        <Dropdown.Item className="my-3">Research</Dropdown.Item>
                                        <Dropdown.Item className="my-3">News</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link menu_outer" >
                                <Dropdown text='Solutions' pointing className='link item nav-link menu_outer col-12 px-4'>
                                    <Dropdown.Menu className='col-12'>
                                        <Dropdown.Header>By Industry</Dropdown.Header>
                                        <Dropdown.Divider />
                                        <Dropdown.Item className="my-3 ml-4">SMART M&T</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">SMART</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">SMART</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">SMART VAS</Dropdown.Item>
                                        <Dropdown.Header>By Flagship Suites</Dropdown.Header>
                                        <Dropdown.Item className="my-3 ml-4">Smart</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Smart Pay</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Smart</Dropdown.Item>
                                        <Dropdown.Header>By Products</Dropdown.Header>
                                        <Dropdown.Item className="my-3 ml-4">Automated Fare Collection (AFC)</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Ride-Hailing & Delivery</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Secure Access Management</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Fleet Management</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Digital Payment Wallet</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Mobile Banking</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Agent Banking App</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Lending Platform</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Core Banking & Micro Financing Platform</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Digital Marketplace & eCommerce</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Loyalty Platform</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Campaign Management Module</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">e-Voucher & e-Gift Card Solution</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">ezBiz</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Tokenization Platform</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Digital Enabling Hub</Dropdown.Item>
                                        <Dropdown.Item className="my-3 ml-4">Digital UX and Communication Platforms</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link menu_outer px-4" href="/#services">
                                Services
                            </a>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link menu_outer px-4" to="/customers" exact>
                                Customers
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link menu_outer px-4" to="/partners" exact>
                                Partners
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink href="" className="nav-link menu_outer px-4" to="/contact" exact >
                                Contact Us
                            </NavLink>
                        </li>
                         <div className="navbar-toggler menu-icon btn-close btn btn-danger mt-3 py-3 btn-block text-white"
                              data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                              aria-expanded="false" aria-label="Toggle navigation" onClick={handleClick}>
                            <i className={click ? 'fas fa-times' : 'fas fa-bars'} /> Close
                        </div>
                    </ul>
                </div>
            </nav>
        </div>
    )
}
export default Navheader;
