import React from "react";
import {Button} from "react-bootstrap";

function Footer() {
    return (
        <div>
            <footer className="footer-bs cDarkGreyBG py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 footer-brand animated fadeInLeft">
                            <a className="navbar-brand" href="/">
                                <div className="site_logo">
                                    <img className="img-fluid" src="../img/embrace-4a.png"
                                         alt="Logo"/>
                                </div>
                            </a>
                            <p>We are an innovative Digital Enabler, helping companies to embrace Digital
                                Technologies to transform their business World.</p>
                            <p>© 2023. Silverleap – All Rights Reserved. Developed By
                                <a href="https://www.facebook.com/pablo.creativesolutions.wslex/" target="_blank"
                                className="text-white" rel="noreferrer"> Pablo Creative Solutions</a> .</p>
                        </div>
                        <div className="col-md-3 footer-nav animated fadeInUp" id="contact_us">
                            <h4>Contact Us </h4>
                            <ul className="list">
                                <li><i className="fa fa-map-marker" aria-hidden="true" /> &nbsp; <a href="/">
                                    No. 351-5th Floor, R. A. De Mel Mawatha, Colombo 03, Sri Lanka.</a></li>
                                <li><i className="fa fa-envelope" aria-hidden="true" /> &nbsp; <a
                                    href="/">info@silverleap.com</a></li>
                                <li><i className="fa fa-phone" aria-hidden="true" /> &nbsp; <a
                                    href="/">+94112732358</a></li>
                                <li><i className="fa fa-link" aria-hidden="true" /> &nbsp; <a
                                    href="/">www.silverleap.com</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3 footer-nav animated fadeInUp">
                            <h4>Company </h4>
                            <ul className="list">
                                <li><a href="/">About Us</a></li>
                                <li><a href="#contact_us">Contacts</a></li>
                                <li><a href="/">Terms & Condition</a></li>
                                <li><a href="/">Privacy Policy</a></li>
                            </ul>

                            <ul className="list social-media">
                                <li className="px-2">
                                    <a href="https://www.facebook.com/silverleaptechnology/" target="_blank" rel="noreferrer">
                                        <i className="fa fa-facebook" aria-hidden="true" />
                                    </a>
                                </li>
                                <li className="px-2">
                                    <a href="https://twitter.com/silverleaptech?lang=en" target="_blank" rel="noreferrer">
                                        <i className="fa fa-twitter" aria-hidden="true" />
                                    </a>
                                </li>
                                <li className="px-2">
                                    <a href="https://lk.linkedin.com/company/silverleaptechnology" target="_blank" rel="noreferrer">
                                        <i className="fa fa-linkedin" aria-hidden="true" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3 footer-ns animated fadeInRight">
                            <h4>SUBSCRIBE</h4>
                            <form id="subscribeForm" onSubmit="return myFunction();">
                                <div className="form-group">
                                    <input type="text" className="form-control" required
                                           placeholder="Enter your Name *"/>
                                </div>
                                <div className="form-group">
                                    <input type="tel" className="form-control" required
                                           placeholder="Enter your Mobile *"/>
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control" required
                                           placeholder="Enter your Email *"/>
                                </div>
                                <div className="form-group ">
                                    <div className=" content">
                                        <Button className="btn" type="submit" role="ubscribe"
                                                id="subscribe-btn">Subscribe Now
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </footer>

            <div id="subscribe-modal" className="modal fade">
                <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="icon-box">
                                <i className="fa fa-check" aria-hidden="true" />
                            </div>
                            <h4 className="modal-title w-100">Thank You!</h4>
                        </div>
                        <div className="modal-body">
                            <p className="text-center">Your Request is Successfully Communicated, Our Team Will
                                Contact You Soon !</p>
                        </div>
                        <div className="modal-footer">
                            <Button  className="btn btn-success btn-block" data-dismiss="modal">OK</Button >
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Footer;
