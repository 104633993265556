import React from 'react'
import '../../App.css';
import Achievement from "../Achievement";
import Ourcustomers from "../Ourcustomers";
import Worldmap from "../Worldmap";


export default function Partners(){
    return(
        <div>
            <section className=" cHomepageHeader cDesktopHeader">
                <div className="container-fluid px-0">
                    <div className="bg-imagesmb">
                        <div className="container">
                            <div className="slide__content--headings px-2 px-lg-5 py-4 mx-lg-5 text-center">
                                <h2 className="animated text-center text-lg-center text-white mdf-h2" data-animation-in="fadeInUp">
                                    Partner Directory</h2>
                                <p className="animated text-center text-white px-lg-5" data-animation-in="fadeInUp"
                                   data-delay-in="0.3">
                                    partners deliver validated services, solutions, and technologies to customers worldwide. Feel free to explore our partner directory or contact us for a referral.
                                </p>
                                <a href="/" id="iHOME-EI-CTA" className="cButton text-center">Explore More </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="">
                    <div className="container login-container my-0">
                        <div className="toolbar mb2 mt2 " id="accordion">

                             <div className="row">
                                <div className="col-sm-6 py-5 ">
                                    <div className="hover-element service-element hover--active">
                                        <div className="hover-element__initial text-center">
                                            <h3>Technical Partners</h3>
                                            <p>
                                                The burden of managing finances can stop a small
                                                <br className="hidden-xs hidden-sm" /> business in its tracks - that’s where Partner comes in.
                                            </p>
                                            <div id="headingOne">
                                                <button className="btn btn-gallary fil-cat btn-lg" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">Technical Partners</button>
                                            </div>

                                        </div>
                                        <div className="hover-element__reveal" data-overlay="7">
                                            <div className="background-image-holder1" >
                                                <img alt="img" src="../img/service1.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 py-5">
                                    <div className="hover-element service-element hover--active">
                                        <div className="hover-element__initial text-center">
                                            <h3>Business Partners</h3>
                                            <p>
                                                We assist you to achieve growth by carefully planning
                                                <br className="hidden-xs hidden-sm" /> a dynamic business strategy plan.
                                            </p>
                                            <button className="btn btn-gallary fil-cat btn-lg" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">Business Partners</button>
                                        </div>
                                        <div className="hover-element__reveal" data-overlay="7">
                                            <div className="background-image-holder2">
                                                <img alt="img" src="../img/service2.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                                <h1 className="font-weight-bold px-3">Technical Partners</h1><hr />
                            <div className="">
                                <div className="bbb_slider_container">
                                    <div className="row">
                                        <div className="bbb_item is_new col-md-3">
                                            <div className=" d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c1.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item col-md-3">
                                            <div className=" d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c2.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item col-md-3">
                                            <div className=" d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c3.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item is_new col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c4.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c5.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item is_new col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c6.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item is_new col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c7.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item is_new col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c8.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item is_new col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c9.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item is_new col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c10.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </div>

                            <div className="">
                                <div id="collapseOne" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                    <h1 className="font-weight-bold px-3">Business Partners</h1>
                                    <hr />
                                    <div className="pt-4">
                                        <div className="form-row justify-content-md-center mx-2">
                                            <div className="col-md-3">
                                                <select className="form-control form-control">
                                                    <option>Product Offered</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-control form-control">
                                                    <option>Region</option>
                                                    <option>South Asia</option>
                                                    <option>South Eastern Asia</option>
                                                    <option>Central & East Asia</option>
                                                    <option>Pacific</option>
                                                    <option>Africa</option>
                                                    <option>Middle East</option>
                                                    <option>Europe</option>
                                                    <option>South America</option>
                                                    <option>North America</option>
                                                </select>
                                            </div>
                                            <div className="col-md-3">
                                                <select className="form-control form-control">
                                                    <option value="  " selected>Select a Country</option>
                                                    <option value="AF">Afghanistan</option>
                                                    <option value="AL">Albania</option>
                                                    <option value="DZ">Algeria</option>
                                                    <option value="AS">American Samoa</option>
                                                    <option value="AD">Andorra</option>
                                                    <option value="AO">Angola</option>
                                                    <option value="AI">Anguilla</option>
                                                    <option value="AQ">Antarctica</option>
                                                    <option value="AG">Antigua and Barbuda</option>
                                                    <option value="AR">Argentina</option>
                                                    <option value="AM">Armenia</option>
                                                    <option value="AW">Aruba</option>
                                                    <option value="AU">Australia</option>
                                                    <option value="AT">Austria</option>
                                                    <option value="AZ">Azerbaijan</option>
                                                    <option value="BS">Bahamas</option>
                                                    <option value="BH">Bahrain</option>
                                                    <option value="BD">Bangladesh</option>
                                                    <option value="BB">Barbados</option>
                                                    <option value="BY">Belarus</option>
                                                    <option value="BE">Belgium</option>
                                                    <option value="BZ">Belize</option>
                                                    <option value="BJ">Benin</option>
                                                    <option value="BM">Bermuda</option>
                                                    <option value="BT">Bhutan</option>
                                                    <option value="BO">Bolivia</option>
                                                    <option value="BA">Bosnia and Herzegowina</option>
                                                    <option value="BW">Botswana</option>
                                                    <option value="BV">Bouvet Island</option>
                                                    <option value="BR">Brazil</option>
                                                    <option value="IO">British Indian Ocean Territory</option>
                                                    <option value="BN">Brunei Darussalam</option>
                                                    <option value="BG">Bulgaria</option>
                                                    <option value="BF">Burkina Faso</option>
                                                    <option value="BI">Burundi</option>
                                                    <option value="KH">Cambodia</option>
                                                    <option value="CM">Cameroon</option>
                                                    <option value="CA">Canada</option>
                                                    <option value="CV">Cape Verde</option>
                                                    <option value="KY">Cayman Islands</option>
                                                    <option value="CF">Central African Republic</option>
                                                    <option value="TD">Chad</option>
                                                    <option value="CL">Chile</option>
                                                    <option value="CN">China</option>
                                                    <option value="CX">Christmas Island</option>
                                                    <option value="CC">Cocos (Keeling) Islands</option>
                                                    <option value="CO">Colombia</option>
                                                    <option value="KM">Comoros</option>
                                                    <option value="CG">Congo</option>
                                                    <option value="CD">Congo, the Democratic Republic of the</option>
                                                    <option value="CK">Cook Islands</option>
                                                    <option value="CR">Costa Rica</option>
                                                    <option value="CI">Cote d'Ivoire</option>
                                                    <option value="HR">Croatia (Hrvatska)</option>
                                                    <option value="CU">Cuba</option>
                                                    <option value="CY">Cyprus</option>
                                                    <option value="CZ">Czech Republic</option>
                                                    <option value="DK">Denmark</option>
                                                    <option value="DJ">Djibouti</option>
                                                    <option value="DM">Dominica</option>
                                                    <option value="DO">Dominican Republic</option>
                                                    <option value="TP">East Timor</option>
                                                    <option value="EC">Ecuador</option>
                                                    <option value="EG">Egypt</option>
                                                    <option value="SV">El Salvador</option>
                                                    <option value="GQ">Equatorial Guinea</option>
                                                    <option value="ER">Eritrea</option>
                                                    <option value="EE">Estonia</option>
                                                    <option value="ET">Ethiopia</option>
                                                    <option value="FK">Falkland Islands (Malvinas)</option>
                                                    <option value="FO">Faroe Islands</option>
                                                    <option value="FJ">Fiji</option>
                                                    <option value="FI">Finland</option>
                                                    <option value="FR">France</option>
                                                    <option value="FX">France, Metropolitan</option>
                                                    <option value="GF">French Guiana</option>
                                                    <option value="PF">French Polynesia</option>
                                                    <option value="TF">French Southern Territories</option>
                                                    <option value="GA">Gabon</option>
                                                    <option value="GM">Gambia</option>
                                                    <option value="GE">Georgia</option>
                                                    <option value="DE">Germany</option>
                                                    <option value="GH">Ghana</option>
                                                    <option value="GI">Gibraltar</option>
                                                    <option value="GR">Greece</option>
                                                    <option value="GL">Greenland</option>
                                                    <option value="GD">Grenada</option>
                                                    <option value="GP">Guadeloupe</option>
                                                    <option value="GU">Guam</option>
                                                    <option value="GT">Guatemala</option>
                                                    <option value="GN">Guinea</option>
                                                    <option value="GW">Guinea-Bissau</option>
                                                    <option value="GY">Guyana</option>
                                                    <option value="HT">Haiti</option>
                                                    <option value="HM">Heard and Mc Donald Islands</option>
                                                    <option value="VA">Holy See (Vatican City State)</option>
                                                    <option value="HN">Honduras</option>
                                                    <option value="HK">Hong Kong</option>
                                                    <option value="HU">Hungary</option>
                                                    <option value="IS">Iceland</option>
                                                    <option value="IN">India</option>
                                                    <option value="ID">Indonesia</option>
                                                    <option value="IR">Iran (Islamic Republic of)</option>
                                                    <option value="IQ">Iraq</option>
                                                    <option value="IE">Ireland</option>
                                                    <option value="IL">Israel</option>
                                                    <option value="IT">Italy</option>
                                                    <option value="JM">Jamaica</option>
                                                    <option value="JP">Japan</option>
                                                    <option value="JO">Jordan</option>
                                                    <option value="KZ">Kazakhstan</option>
                                                    <option value="KE">Kenya</option>
                                                    <option value="KI">Kiribati</option>
                                                    <option value="KP">Korea, Democratic People's Republic of</option>
                                                    <option value="KR">Korea, Republic of</option>
                                                    <option value="KW">Kuwait</option>
                                                    <option value="KG">Kyrgyzstan</option>
                                                    <option value="LA">Lao People's Democratic Republic</option>
                                                    <option value="LV">Latvia</option>
                                                    <option value="LB">Lebanon</option>
                                                    <option value="LS">Lesotho</option>
                                                    <option value="LR">Liberia</option>
                                                    <option value="LY">Libyan Arab Jamahiriya</option>
                                                    <option value="LI">Liechtenstein</option>
                                                    <option value="LT">Lithuania</option>
                                                    <option value="LU">Luxembourg</option>
                                                    <option value="MO">Macau</option>
                                                    <option value="MK">Macedonia, The Former Yugoslav Republic of</option>
                                                    <option value="MG">Madagascar</option>
                                                    <option value="MW">Malawi</option>
                                                    <option value="MY">Malaysia</option>
                                                    <option value="MV">Maldives</option>
                                                    <option value="ML">Mali</option>
                                                    <option value="MT">Malta</option>
                                                    <option value="MH">Marshall Islands</option>
                                                    <option value="MQ">Martinique</option>
                                                    <option value="MR">Mauritania</option>
                                                    <option value="MU">Mauritius</option>
                                                    <option value="YT">Mayotte</option>
                                                    <option value="MX">Mexico</option>
                                                    <option value="FM">Micronesia, Federated States of</option>
                                                    <option value="MD">Moldova, Republic of</option>
                                                    <option value="MC">Monaco</option>
                                                    <option value="MN">Mongolia</option>
                                                    <option value="MS">Montserrat</option>
                                                    <option value="MA">Morocco</option>
                                                    <option value="MZ">Mozambique</option>
                                                    <option value="MM">Myanmar</option>
                                                    <option value="NA">Namibia</option>
                                                    <option value="NR">Nauru</option>
                                                    <option value="NP">Nepal</option>
                                                    <option value="NL">Netherlands</option>
                                                    <option value="AN">Netherlands Antilles</option>
                                                    <option value="NC">New Caledonia</option>
                                                    <option value="NZ">New Zealand</option>
                                                    <option value="NI">Nicaragua</option>
                                                    <option value="NE">Niger</option>
                                                    <option value="NG">Nigeria</option>
                                                    <option value="NU">Niue</option>
                                                    <option value="NF">Norfolk Island</option>
                                                    <option value="MP">Northern Mariana Islands</option>
                                                    <option value="NO">Norway</option>
                                                    <option value="OM">Oman</option>
                                                    <option value="PK">Pakistan</option>
                                                    <option value="PW">Palau</option>
                                                    <option value="PA">Panama</option>
                                                    <option value="PG">Papua New Guinea</option>
                                                    <option value="PY">Paraguay</option>
                                                    <option value="PE">Peru</option>
                                                    <option value="PH">Philippines</option>
                                                    <option value="PN">Pitcairn</option>
                                                    <option value="PL">Poland</option>
                                                    <option value="PT">Portugal</option>
                                                    <option value="PR">Puerto Rico</option>
                                                    <option value="QA">Qatar</option>
                                                    <option value="RE">Reunion</option>
                                                    <option value="RO">Romania</option>
                                                    <option value="RU">Russian Federation</option>
                                                    <option value="RW">Rwanda</option>
                                                    <option value="KN">Saint Kitts and Nevis</option>
                                                    <option value="LC">Saint LUCIA</option>
                                                    <option value="VC">Saint Vincent and the Grenadines</option>
                                                    <option value="WS">Samoa</option>
                                                    <option value="SM">San Marino</option>
                                                    <option value="ST">Sao Tome and Principe</option>
                                                    <option value="SA">Saudi Arabia</option>
                                                    <option value="SN">Senegal</option>
                                                    <option value="SC">Seychelles</option>
                                                    <option value="SL">Sierra Leone</option>
                                                    <option value="SG">Singapore</option>
                                                    <option value="SK">Slovakia (Slovak Republic)</option>
                                                    <option value="SI">Slovenia</option>
                                                    <option value="SB">Solomon Islands</option>
                                                    <option value="SO">Somalia</option>
                                                    <option value="ZA">South Africa</option>
                                                    <option value="GS">South Georgia and the South Sandwich Islands</option>
                                                    <option value="ES">Spain</option>
                                                    <option value="LK">Sri Lanka</option>
                                                    <option value="SH">St. Helena</option>
                                                    <option value="PM">St. Pierre and Miquelon</option>
                                                    <option value="SD">Sudan</option>
                                                    <option value="SR">Suriname</option>
                                                    <option value="SJ">Svalbard and Jan Mayen Islands</option>
                                                    <option value="SZ">Swaziland</option>
                                                    <option value="SE">Sweden</option>
                                                    <option value="CH">Switzerland</option>
                                                    <option value="SY">Syrian Arab Republic</option>
                                                    <option value="TW">Taiwan, Province of China</option>
                                                    <option value="TJ">Tajikistan</option>
                                                    <option value="TZ">Tanzania, United Republic of</option>
                                                    <option value="TH">Thailand</option>
                                                    <option value="TG">Togo</option>
                                                    <option value="TK">Tokelau</option>
                                                    <option value="TO">Tonga</option>
                                                    <option value="TT">Trinidad and Tobago</option>
                                                    <option value="TN">Tunisia</option>
                                                    <option value="TR">Turkey</option>
                                                    <option value="TM">Turkmenistan</option>
                                                    <option value="TC">Turks and Caicos Islands</option>
                                                    <option value="TV">Tuvalu</option>
                                                    <option value="UG">Uganda</option>
                                                    <option value="UA">Ukraine</option>
                                                    <option value="AE">United Arab Emirates</option>
                                                    <option value="GB">United Kingdom</option>
                                                    <option value="US">United States</option>
                                                    <option value="UM">United States Minor Outlying Islands</option>
                                                    <option value="UY">Uruguay</option>
                                                    <option value="UZ">Uzbekistan</option>
                                                    <option value="VU">Vanuatu</option>
                                                    <option value="VE">Venezuela</option>
                                                    <option value="VN">Viet Nam</option>
                                                    <option value="VG">Virgin Islands (British)</option>
                                                    <option value="VI">Virgin Islands (U.S.)</option>
                                                    <option value="WF">Wallis and Futuna Islands</option>
                                                    <option value="EH">Western Sahara</option>
                                                    <option value="YE">Yemen</option>
                                                    <option value="YU">Yugoslavia</option>
                                                    <option value="ZM">Zambia</option>
                                                    <option value="ZW">Zimbabwe</option>
                                                </select>
                                            </div>
                                            <div className="col-md-1">
                                                <button type="submit" className="btn btn-gallary btn-lg btn-block">Submit</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="py-5 ">
                                        <div className="bbb_slider_container">
                                             <div className="row">
                                        <div className="bbb_item is_new col-md-3">
                                            <div className=" d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c1.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item col-md-3">
                                            <div className=" d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c2.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item col-md-3">
                                            <div className=" d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c3.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item is_new col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c4.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c5.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item is_new col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c6.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item is_new col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c7.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item is_new col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c8.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item is_new col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c9.png" alt="" />
                                            </div>
                                        </div>

                                        <div className="bbb_item is_new col-md-3">
                                            <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                                <img className="img-fluid" src="../img/c10.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                        </div>
                                    </div>


                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="">
                    <div className="container login-container my-0">
                        <div className="row">
                            <div className="col-md-6 login-form-1">
                                <div className=" text-center">
                                    <h2 className="text-white font-weight-bold">Existing Partners</h2>
                                    <a id="iHOME-EI-CTA" className="cButton btn-gallary text-center btn-lg" href="/">Loging Now! </a>
                                </div>
                            </div>
                            <div className="col-md-6 login-form-2">
                                <div className="login-logo">
                                    <img src="https://image.ibb.co/n7oTvU/logo_white.png" alt="" />
                                </div>
                                <div className="text-center">
                                    <h2 className="text-white font-weight-bold">Registrar New Partners</h2>
                                    <a id="iHOME-EI-CTA" className="cButton btn-gallary text-center btn-lg" href="/">Registrar  Now! </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Achievement/>
            <Ourcustomers/>
            <Worldmap/>
        </div>
    );
}

