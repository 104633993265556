import React from "react";
import '../../App.css';
import Slidermain from "../Slidermain";
import Smartproduct from "../Smartproduct";
import Services from "../Services";
import Portfolio from "../Portfolio";
import Achievement from "../Achievement";
import Ourcustomers from "../Ourcustomers";
import Worldmap from "../Worldmap";

function Home() {
    return(
        <div>
            <Slidermain/>
            <Smartproduct/>
            <Services/>
            <Portfolio/>
            <Achievement/>
            <Ourcustomers/>
            <Worldmap/>
        </div>
    );
}

export default Home;
