import React from "react";
import './Achievement.css';

function Achievement() {
    return(
        <div>
            <section className="bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="four col-md-3">
                                <div className=" text-center py-4">
                                    <div className="row justify-content-md-center">
                                        <div className="col-md-2 col-4">
                                            <span><i className="fa fa-plus fa-3x" aria-hidden="true" /></span>
                                        </div>
                                        <div className="col-md-8 col-7 text-center ">
                                            <h2 className="counter  mb-3 font-weight-bold"> 750</h2>
                                            <h3 className="mt-2 mb-0 font-weight-bold">Experience</h3>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="four col-md-3">
                                <div className=" text-center py-4">
                                    <div className="row justify-content-md-center">
                                        <div className="col-md-2 col-4">
                                            <span><i className="fa fa-plus fa-3x" aria-hidden="true" /></span>
                                        </div>
                                        <div className="col-md-8 col-7 text-center ">
                                            <h2 className="counter mb-3 font-weight-bold"> 55</h2>
                                            <h3 className="mt-2 mb-0 font-weight-bold">Our Team</h3>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="four col-md-3">
                                <div className=" text-center py-4">
                                    <div className="row justify-content-md-center">
                                        <div className="col-md-2 col-4">
                                            <span><i className="fa fa-plus fa-3x" aria-hidden="true" /></span>
                                        </div>
                                        <div className="col-md-8 col-7 text-center ">
                                            <h2 className="counter h2 mb-3 font-weight-bold"> 22</h2>
                                            <h3 className="mt-2 mb-0 font-weight-bold">Clients</h3>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="four col-md-3">
                                <div className=" text-center py-4">
                                    <div className="row justify-content-md-center">
                                        <div className="col-md-2 col-4">
                                            <span><i className="fa fa-plus fa-3x" aria-hidden="true" /></span>
                                        </div>
                                        <div className="col-md-8 col-7 text-center">
                                            <h2 className="counter h2 mb-3 font-weight-bold"> 45</h2>
                                            <h3 className="mt-2 mb-0 font-weight-bold">Project</h3>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </div>
    )
}
export default Achievement;
