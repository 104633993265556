import React, { useState, useEffect } from 'react'
import './Header.css';
import 'semantic-ui-css/semantic.min.css';
// import { Button } from './Button';
import {Link} from "react-router-dom";


function Header() {
    const [ setClick] = useState(false);
    const [button, setButton] = useState(true);

    // const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);
    return (
        <nav className="cWSO2DeskNav navbar navbar-expand-lg navbar-light sticky-top">
            <div className="container">
                 <Link
                     to='/'
                     className='navbar-brand '
                     onClick={closeMobileMenu} >
                     <div className="site_logo">
                        <img src="../img/embrace-4a.png" alt="Logo" />
                    </div>
                 </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="/mobile_nav"
                        aria-controls="mobile_nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>

                <div className="collapse navbar-collapse" id="mobile_nav">
                    <ul className="navbar-nav ml-auto mt-2 mt-lg-0 float-md-right">
                        <li className="nav-item dropdown megamenu-li dmenu">

                            <a className="nav-link dropdown-toggle" href="/" id="dropdown01" data-toggle="dropdown" aria-expanded="false">Company</a>
                            <div className="dropdown-menu megamenu sm-menu border-top"
                                 aria-labelledby="dropdown01">
                                <div className="container">
                                    <div className="row cInlineMenuRow">
                                        <div className="col-md-12">
                                            <div className="cSelected cCompany" />
                                        </div>
                                        <div className="col-sm-12 col-lg-2 border-right cMegamenuCol mb-4" />
                                        <div className="col-sm-12 col-lg-2 border-right cMegamenuCol mb-2">
                                            <a className="cMenuLinkTracking nav-link dropdown-item"
                                               href="/"> About</a>
                                            <a className="cMenuLinkTracking nav-link dropdown-item"
                                               href="/"> Team</a>
                                            <a className="cMenuLinkTracking nav-link dropdown-item"
                                               href="/"> Careers</a>
                                        </div>
                                        <div className="col-sm-12 col-lg-2 border-right cMegamenuCol mb-2">
                                            <a className="cMenuLinkTracking nav-link dropdown-item"
                                               href="/"> Community</a>
                                            <a className="cMenuLinkTracking nav-link dropdown-item"
                                               href="/"> Research</a>
                                            <a className="cMenuLinkTracking nav-link dropdown-item"
                                               href="/"> News</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown megamenu-li dmenu">
                            <a className="nav-link dropdown-toggle" href="/" id="dropdown02" data-toggle="dropdown" aria-expanded="false">Solutions</a>
                            <div className="dropdown-menu megamenu sm-menu border-top"
                                 aria-labelledby="dropdown02">
                                <div className="container">
                                    <div className="row cInlineMenuRow px-3">
                                        <div className="col-md-12 col-lg-1">
                                            <div className="cSelected cSolutions" />
                                        </div>
                                        <div className="col-sm-12 col-lg-2 border-right cMegamenuCol mb-4">
                                            <h6>By Industry</h6>
                                            <a className="cMenuLinkTracking nav-link dropdown-item"
                                               href="/"> SMART
                                                M&T </a>
                                            <a className="cMenuLinkTracking nav-link dropdown-item"
                                               href="/"> SMART
                                                BFSI </a>
                                            <a className="cMenuLinkTracking nav-link dropdown-item"
                                               href="/"> SMART
                                                RETAIL </a>
                                            <a className="cMenuLinkTracking nav-link dropdown-item"
                                               href="/"> SMART
                                                VAS </a>
                                        </div>
                                        <div className="col-sm-12 col-lg-3 border-right cMegamenuCol mb-4">
                                            <h6>By Flagship Suites</h6>
                                            <a className="cMenuLinkTracking nav-link dropdown-item"
                                               href="/"> Smart
                                                Transit </a>
                                            <a className="cMenuLinkTracking nav-link dropdown-item"
                                               href="/"> Smart
                                                Pay </a>
                                            <a className="cMenuLinkTracking nav-link dropdown-item"
                                               href="/"> Smart
                                                Shopper </a>

                                        </div>
                                        <div className="col-sm-12 col-lg-6 cMegamenuCol">
                                            <h6>By Products</h6>
                                            <div className="row cInlineMenuRow mb-3">
                                                <div
                                                    className="col-sm-12 col-lg-5 border-right cMegamenuCol mb-2 pl-0">
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Automated Fare Collection (AFC)</a>
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Ride-Hailing & Delivery</a>
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Secure Access Management</a>
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Fleet Management</a>
                                                </div>
                                                <div
                                                    className="col-sm-12 col-lg-5 border-right cMegamenuCol mb-2 ml-4">
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Digital Payment Wallet</a>
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Mobile Banking</a>
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Agent Banking App</a>
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Lending Platform</a>
                                                </div>

                                            </div>
                                            <div className="row cInlineMenuRow ">
                                                <div
                                                    className="col-sm-12 col-lg-5 border-right cMegamenuCol mb-2 pl-0">
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Digital Marketplace & eCommerce </a>
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Loyalty Platform</a>
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Campaign Management Module</a>
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> e-Voucher & e-Gift Card Solution</a>
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> ezBiz *</a>
                                                </div>
                                                <div
                                                    className="col-sm-12 col-lg-5 border-right cMegamenuCol mb-2 ml-4">
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Tokenization Platform</a>
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Digital Enabling Hub</a>
                                                    <a className="cMenuLinkTracking nav-link dropdown-item"
                                                       href="/"> Digital UX & Communication Platforms *</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="cMenuLinkTracking nav-link cSingleLink" href="/#services">Services </a>
                        </li>
                        <li className="nav-item">
                             <Link
                                 to='/customers'
                                 className='cMenuLinkTracking nav-link cSingleLink '
                                 onClick={closeMobileMenu} >
                                 Customers
                             </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to='/partners'
                                className='cMenuLinkTracking nav-link cSingleLink '
                                onClick={closeMobileMenu} >
                                 Partners
                             </Link>
                        </li>
                    </ul>
                    <div className="cMenuPositionRelative">
                        <ul className="navbar-nav navbar-light cMenuMargin">
                            <li className="nav-item">
                                <Link
                                    to='/contact'
                                    className='cMenuLinkTracking cContactButton text-md-center text-sm-left'
                                    onClick={closeMobileMenu} >
                                 Contact
                             </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}
export default Header;
