import React from "react";
import './Ourcustomers.css';


function Ourcustomers(){
    return(
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="">
                            <h2 className="bbb_title text-uppercase mb-0">Our</h2>
                            <h1 className="text-uppercase font-weight-bold mb-0">Customers</h1>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="bbb_slider_container">
                            <div className="owl-carousel owl-theme bbb_slider">
                                <div className="owl-item">
                                    <div className="bbb_item is_new">
                                        <div className=" d-flex flex-column align-items-center justify-content-center cliens">
                                            <img className="img-fluid" src="../img/c1.png"  alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="bbb_item">
                                        <div className=" d-flex flex-column align-items-center justify-content-center cliens">
                                            <img className="img-fluid" src="../img/c2.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="bbb_item">
                                        <div className=" d-flex flex-column align-items-center justify-content-center cliens">
                                            <img className="img-fluid" src="../img/c3.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="bbb_item is_new">
                                        <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                            <img className="img-fluid" src="../img/c4.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="bbb_item">
                                        <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                            <img className="img-fluid" src="../img/c5.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="bbb_item is_new">
                                        <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                            <img className="img-fluid" src="../img/c6.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="bbb_item is_new">
                                        <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                            <img className="img-fluid" src="../img/c7.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="bbb_item is_new">
                                        <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                            <img className="img-fluid" src="../img/c8.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="bbb_item is_new">
                                        <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                            <img className="img-fluid" src="../img/c9.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="owl-item">
                                    <div className="bbb_item is_new">
                                        <div className="d-flex flex-column align-items-center justify-content-center cliens">
                                            <img className="img-fluid" src="../img/c10.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ourcustomers;


