import React from 'react';
import './Slidermain.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'semantic-ui-css/semantic.min.css';
import Slider from "react-slick";


function Slidermain() {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }

        const settings = {
            dots: true,
            infinite: true,
            speed: 2500,
            fade: !0,
            cssEase: 'linear',
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 8000,
            pauseOnHover: true,
            draggable: true,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        draggable: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        draggable: true,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        draggable: true,
                        slidesToScroll: 1
                    }
                }
            ]
        }
        return(
            <div>
            <section className=" cHomepageHeader cDesktopHeader">
                <div className="container-fluid px-0">

                    <div className="d-sm-block d-lg-none d-xl-none">
                        <div className="bg-imagesmb">
                            <div className="container">

                                <div className="slide__content--headings py-5">
                                    <h2 className="animated text-left text-white" data-animation-in="fadeInUp">A
                                        DIGITAL ENABLER
                                        WITH A RANGE OF FINTECH AND DIGITAL SOLUTIONS.</h2>
                                    <p className="animated text-left text-white" data-animation-in="fadeInUp"
                                       data-delay-in="0.3">
                                        There’s nothing that can make us happy than seeing how we could contribute
                                    </p>
                                    <a href="/" id="iHOME-EI-CTA" className="cButton text-left">Explore More </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Slider {...settings} className="mb-none d-none d-sm-block">

                    <div className="slide">
                        <div className="slide__img">
                            <img src="../img/main01.png" alt="" data-lazy="./img/main01.png"
                                 className="full-image animated" data-animation-in="zoomInImage" />
                        </div>
                        <div className="container">
                            <div className="slide__content--headings">
                                <div className="col-md-12">
                                    <h2 className="animated text-left" data-animation-in="fadeInUp">A DIGITAL ENABLER
                                        WITH A RANGE OF FINTECH AND DIGITAL SOLUTIONS.</h2>
                                    <p className="animated text-left" data-animation-in="fadeInUp" data-delay-in="0.3">
                                        There’s nothing that can make us happy than seeing how we could contribute to
                                        the evolution of mankind into the digital space.
                                        This was our base on top of what we built what we call “Silverleap”.
                                    </p>
                                    <div className="animated text-left" data-animation-in="fadeInUp"
                                         data-delay-in="0.4">
                                        <a href="/" id="iHOME-EI-CTB" className="animated btn cButton">Explore More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="slide__img">
                            <img src="../img/main03.png" alt="" data-lazy="./img/main03.png"
                                 className="full-image animated" data-animation-in="zoomInImage" />
                        </div>
                        <div className="container">
                            <div className="slide__content--headings pt-5 mt-3">
                                <div className="col-md-6">
                                    <h2 className="animated text-left text-gray mdf-h2"
                                        data-animation-in="fadeInUp">SMART TRANSIT</h2>
                                    <p className="animated text-left text-gray mdf-para"
                                       data-animation-in="fadeInUp" data-delay-in="0.1">
                                        Smart Transit, is a Comprehensive, Interoperable, Open Standard Mobility &
                                        Transportation Platform to Track,
                                        Collect & Automate transportation operation workflows, fleet, assets,
                                        routes, customers, drivers and other stakeholders.
                                        To offer seamless journey with special convenience for user Passengers for
                                        their daily commutation with transport and
                                        for service operators to provide services without hassles.
                                    </p>
                                    <div className="animated text-left" data-animation-in="fadeInUp"
                                         data-delay-in="0.4">
                                        <a href="/" id="iHOME-EI-CTB" className="animated btn cButton">Explore More</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="slide__img">
                            <img src="../img/main02.png" alt="" data-lazy="./img/main02.png"
                                 className="full-image animated" data-animation-in="zoomInImage" />
                        </div>
                        <div className="container">
                            <div className="slide__content--headings">
                                <div className="col-md-12">
                                    <h2 className="animated text-left" data-animation-in="fadeInRight">PROVIDE
                                        ENTERPRISE-GRADE, INNOVATIVE AND DISTINCT DIGITAL SOLUTIONS</h2>
                                    <p className="animated text-left" data-animation-in="fadeInRight"
                                       data-delay-in="0.2">
                                        FinTech, we provide enterprise-grade, innovative and distinct digital solutions
                                        anchored in FinTech to our client’s, which are
                                        turnkey, unique and continuously updated with the industry standards and tech
                                        disruptions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="slide__img">
                            <img src="../img/main04.png" alt="" data-lazy="./img/main04.png"
                                 className="full-image animated" data-animation-in="zoomInImage" />
                        </div>
                        <div className="container">
                            <div className="slide__content--headings pt-5 mt-3">
                                <div className="col-md-6">
                                    <h2 className="animated text-left text-gray mdf-h2"
                                        data-animation-in="fadeInUp">SMART PAY</h2>
                                    <p className="animated text-left text-gray mdf-para"
                                       data-animation-in="fadeInUp" data-delay-in="0.1">
                                        Smart Pay, is a comprehensive mobile centric – Bank In Your Palm Platform,
                                        to enhance and re-envision customer
                                        experience through Innovative digital payments and value-added services to
                                        accommodate banking, finance, selfcare,
                                        ecommerce & retail kind of lifestyle service requirements. This highly
                                        scalable and nimble platform, which complies
                                        with industry grade security standards and globally recognized best
                                        practices,...
                                    </p>
                                    <div className="animated text-left" data-animation-in="fadeInUp"
                                         data-delay-in="0.4">
                                        <a href="/" id="iHOME-EI-CTB" className="animated btn cButton">Explore More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="slide__img">
                            <img src="../img/main01.png" alt="" data-lazy="./img/main01.png"
                                 className="full-image animated" data-animation-in="zoomInImage" />
                        </div>
                        <div className="container">
                            <div className="slide__content--headings">
                                <div className="col-md-12">
                                    <h2 className="animated text-left" data-animation-in="fadeInRight">OPTIMIZE BUSINESS
                                        PROCESSES AND CREATE NEW BUSINESS MODELS</h2>
                                    <p className="animated text-left" data-animation-in="fadeInRight"
                                       data-delay-in="0.2">
                                        Working in multiple industry verticals, we strive to help our client to build
                                        digital capabilities that can be deployed to
                                        re-envision customer experience,optimize business processes and create new
                                        business models that enables them to create competitive advantage in the dynamic
                                        digital economy.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <div className="slide__img">
                            <img src="../img/main05.png" alt="" data-lazy="../img/main05.png"
                                 className="full-image animated" data-animation-in="zoomInImage" />
                        </div>
                        <div className="container">
                            <div className="slide__content--headings pt-5 mt-3">
                                <div className="col-md-6">
                                    <h2 className="animated text-left text-gray mdf-h2"
                                        data-animation-in="fadeInUp">SMART SHOPPER</h2>
                                    <p className="animated text-left text-gray mdf-para"
                                       data-animation-in="fadeInUp" data-delay-in="0.1">
                                        Smart Shopper, is a comprehensive Mobile centric, Digital Platform for all
                                        the users across entire retail
                                        value chain, which creates a positive customer experience and better synergy
                                        with whole stakeholders.
                                        Platform provides seamless lifestyle retail and value-added services to the
                                        consumers while enabling Wurth
                                        and its partner network to manage businesses efficiently, effectively and to
                                        grow their business quickly,
                                        steadily with proactive revenue generating channels.
                                    </p>
                                    <div className="animated text-left" data-animation-in="fadeInUp"
                                         data-delay-in="0.4">
                                        <a href="/" id="iHOME-EI-CTB" className="animated btn cButton">Explore More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </Slider>
        </div>
    );
}

export default Slidermain;




