import React from "react";
import Navheader from './components/Navbar';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Home from "./components/Pages/Home";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Customers from "./components/Pages/Customers";
import Partners from "./components/Pages/Partners";
import Contact from "./components/Pages/Contact";


function App() {
  return (
      <>
          <Router>
                <div className="App">
                    <Header />
                    <Navheader />
                    <Switch>
                        <Route path='/' exact component={Home} />
                        <Route path='/customers' component={Customers} />
                        <Route path='/partners' component={Partners} />
                        <Route path='/contact' component={Contact} />
                    </Switch>
                    <Footer />
                </div>
            </Router>
      </>
  );
}

export default App;

